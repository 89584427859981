.ant-card {
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  /* Add more styles as needed to match the design */
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: bold;
  /* Add more styles as needed to match the design */
}

.question-input .ant-input {
  border-radius: 22px;
  /* Add more styles as needed to match the design */
}

.question-input .ant-typography {
  font-weight: 600;
  margin-bottom: 8px;
  /* Adjust based on your design */
}

.question-input .ant-input {
  border-radius: 22px;
  /* Rounded corners as seen in the design */
  padding: 12px;
  /* Or whatever matches your design */
  font-size: 16px;
  /* Adjust as needed */
}

.question-input .ant-input-suffix {
  display: flex;
  align-items: center;
}

.question-input .ant-input-suffix img {
  /* Adjust your icon size as needed */
  width: 20px;
  height: 20px;
}

.card {
  background-color: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #cccccc;
  max-height: 300px;
  overflow-y: scroll;
}

/* Apply to all scrollbars within the .scroll-bar element */
.scroll-bar::-webkit-scrollbar {
  width: 10px;
  /* Total width including the desired space on the sides */
}

/* Style the track */
.scroll-bar::-webkit-scrollbar-track {
  background: #d9d9d9;
  /* Color of the track */
  border-radius: 6px;
  /* Rounded corners for the track */
  box-shadow: inset 2px 0 0 0 white, inset -2px 0 0 0 white,
    inset 0 2px 0 0 white, inset 0 -2px 0 0 white;
  /* Shadows for spacing on all sides */
}

/* Style the thumb */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #070908;
  /* Color of the thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
  border: 2px solid transparent;
  /* Transparent border for spacing from the track sides */
  background-clip: content-box;
  /* Prevents the background color from spilling over the border */
  margin-top: 2px;
  /* Margin on top of the thumb */
  margin-bottom: 2px;
  /* Margin at the bottom of the thumb */
}



.chat-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  height: 500px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  flex-direction:column;
  padding-bottom: 5px;
}

.chat-box .header{
  height: 48px;
  width:100%;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  position:sticky;
  top:0;
  background-color: #fff;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0);
  padding-left:10px;
  gap:2
}

.chat-box .description-header{
  position: sticky;
  top:0;
  max-height:200px;
  background-color: #fff;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
}


.chat-box .header .header-title{
  margin-bottom: 0 !important;
}

.chat-box .main-content{
  overflow-y: scroll;
}

.chat-box input {
  flex: 1;
  margin-right: 10px;
}

.message-field-box{
  padding:0 5px;
  display: flex;
  width: 100%;
}
/* Challenges List style */

.challenges-user{
  width:300px !important;
  background-color: #fff;
  height:100%;
  overflow-y: scroll;
}
.challenges-user-list{
  background-color: #fff;
}

.challenges-user-list li{
  margin-top: 5px !important;
  cursor: pointer;
}

/* Hint Styles */

.hint-user {
  background-color: #fff;
  padding:0 !important
}

.hint-user .message{
  border:0.5px solid #e8e8e8;
  padding:5px
}

.hint-banner{
display:flex;
flex-direction:column;
gap:5;
align-items:center;
padding:5px;
border-radius:8px;
margin-top: 10px;
cursor: pointer;
}
.hint-banner-dark{
background:#49C18E;
color:white;
}
.hint-banner-light{
background:#000D17;
color:#000D17;
}
/* Feedback styles */
.main-layout-feedback{
  padding:0 !important;
}
.timer-section{
width:50px;
height:50px;
}
.react-pdf__Page__canvas {
margin: 0 auto;
width:80% !important;
height: 100% !important;
}
.react-resizable-handle-se, .react-resizable-handle-ne{
z-index: 10 !important;
}

/* pdf viewer */
.ant-drawer-body{
padding:0 !important
}