.btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #888a8d;
}

.btnActive {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
}

.title {
  font-size: 56px;
  font-weight: 400;
  line-height: 72px;
  color: #ffffff;
}

.title span {
  color: #34c788;
}

.description {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #ffffff60;
}



/* New style */

.heading{
  font-size: 56px;
  font-weight: 400;
  line-height: 72px;
  text-align: center;
  color:white
}

.description-challenge{
font-size: 16px;
font-weight: 300;
line-height: 24px;
text-align: center;
color: #FFFFFF99;
}

.item-of-description{
  display: flex;
  align-items: center;
  gap:10px;
  width: 400px;
}

.item-of-description .number{
  width: 36px;
  height: 36px;
  gap: 0px;
  border: 1px solid #A0A2A3;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.item-of-description .description-t{
 text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width:330px
}