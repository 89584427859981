
  .heading-landing {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    
  }

  .description-landing{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
color:#888D89    
  }

  .assessment-description-landing{
    font-family: Poppins;
    font-size: 11.48px;
    font-weight: 300;
    line-height: 17.22px;
    text-align: center;
    color:#888A8D;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .assessment-heading-landing{
    font-family: Poppins;
    font-size: 20.92px;
    font-weight: 600;
    line-height: 34.87px;
    text-align: center;
    
  }

  .landing-buttons{
    padding: 16px;
    gap: 8px;
    border-radius: 8px;
    height:48px;
    width:240px !important;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color:#1E1D1D;
    display: flex;
    justify-content: center;
    align-items: center;

  }