@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

.border-none {
    border: none ;
}