.App {
  text-align: center;
}

* {
  font-family: 'Poppins', sans-serif;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.heading-platform{
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
color:#070908
}

.upload-profile-image .ant-upload-list-item-actions a {
  display: none;
}

.user-info-drop-down  ul{
  width: 150px !important;
}

.user-info-drop-down  ul li span p{
  color: #070908 !important;
}
.user-info-drop-down  ul li:hover{
  background-color: antiquewhite;
}