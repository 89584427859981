.selection-dropdown .ant-btn-compact-first-item{
    width: 197px !important;
    height: 40px !important;
    padding: 8px 16px 8px 16px !important;
    gap: 32px !important;
    border-radius: 8px 0px 0px 8px !important;
    border: 1px 0px 0px 0px !important;
    opacity: 0px !important;
}

.selection-dropdown .ant-btn-compact-first-item span{
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;

}

.selection-dropdown button{
    height: 40px !important;
}

.dashboard-heading{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color:#070908
}

.statistic-card{
    width:100%;
    height: 130px;
    box-shadow: 0px 1px 2px 0px #0000001A !important;

}
.statistic-card .ant-card-body{
    width: 100% !important;
    height: 130px;
    padding: 16px 16px 20px 16px;
    gap: 8px;
    border-radius: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap:10px;
    align-items: start;
}

.statistic-card .ant-card-body .title {
    font-family: Poppins;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color:#888A8D
}

.statistic-card .ant-card-body .description{
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    color:#070908
} 

.card-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap:50px;
}

.bar-char-card{
    min-height:451px;
    position:relative;
    box-shadow: 0px 1px 2px 0px #0000001A !important;
}
.bar-char-card .ant-card-body{
    min-height:451px;
    display: flex;
    flex-direction: column;
    gap:10px;
    padding:0 24px !important
}

.bar-chart-container .ant-progress-inner .ant-progress-bg{
    border-radius: 8px !important;
}
.bar-chart-container{
    display: flex;
    justify-content: space-between;
    margin-top: 7%;
}

.headings-container{
    display: flex;
    justify-content: space-around;
    margin-top:5%;
}

.table-container{
    padding: 24px 0px 0px 0px;
    gap: 8px;
    border-radius: 10px;
    margin-top: 40px;
    box-shadow: 0px 1px 2px 0px #0000001A !important;
}

.heading-div p{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color:#888A8D
}
.bar-chart-container .ant-progress-text{
    rotate:90deg !important
}

/* CustomProgress.css */
.custom-progress-wrapper {
    position: relative;
    width: 100%;
  }
  
  .diagonal-lines-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent);
    background-size: 8px 8px;
    border-radius: 2px; /* Match this to the Progress bar's border radius if necessary */
  }