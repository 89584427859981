.custom-dropdown .ant-btn {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 22px;
    padding: 5px 15px;
    color: #000;
    /* Adjust the font size as necessary to match your design */
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* Or a specific pixel width to match your design */
}

/* Style the icon specifically */
.dropdown-icon {
    margin-right: 8px;
    /* Adjust spacing as necessary */
}

/* If you need to adjust the dropdown icon that comes with Ant Design */
.custom-dropdown .ant-btn .anticon {
    color: #000;
    font-size: 16px;
    /* Adjust size as necessary */
}