.btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #888a8d;
}

.btnActive {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
}

.title {
  font-size: 56px;
  font-weight: 400;
  line-height: 72px;
  color: #ffffff;
}

.title span {
  color: #34c788;
}

.description {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #ffffff60;
}
