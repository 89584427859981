.charts-card .antd-card-body{
    width:100%;
    min-height: 100%;
}

.additional-details-collapse .ant-collapse-header .ant-collapse-header-text {
    flex:none !important
}

.ant-segmented-group {
    border:1px solid #ccc;
    border-radius: 8px;
    overflow: hidden !important;
}

.ant-segmented-group label{
    border-right:1px solid #ccc;
    border-radius: 0px !important;
}