.main-layout{
    height:100%;
    width:100%;
    display:grid;
    grid-template-columns: 70% 30%;
    gap:10px
}
.assessment-details{
    display: flex;
    justify-content: space-between;
    gap:30px;
    padding-right:10px;
    height:auto;
}

.information-container{
    width: 100%;
    min-height: 300px;
    padding: 16px 16px 20px 16px;
    border-radius: 10px;
}

.information-container 
.ant-card-body{
    display: flex;
    justify-content: space-between;
}
.information-container 
.ant-card-body::before,.information-container 
.ant-card-body::after{
    display: none !important;
}

.notes-container .info-container{
    width: 100%;
    padding: 16px 16px 20px 16px;
    border-radius: 10px;
    
}

.info-container{
    height: 352px;
    display: flex;
    flex-direction: column;
}

.notes-container .ant-card-body{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.notes-container .notes{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.history-container {
    display: flex;
    flex-direction: column;
    gap:15px;
}

.history-section{
    gap:30px
}
.question-history-container .ant-card-body::before ,.question-history-container .ant-card-body::after{
display: none;
}
.question-history-container .ant-card-body{
    display: flex;
    flex-direction: column;
    gap:10px;
    padding: 22px
}
.selection-dropdown-history{
    width:200px !important
}