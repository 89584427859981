.title {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  color: #ffffff;
}

.description-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #888d89;
}

.custom-form-item .input {
  background-color: #0a1510 !important;
  color: #ffffff;
  height: 44px;
}

.custom-form-item .input::placeholder {
  color: #888a8d;
}

.custom-input .ant-input {
  background-color: #0a1510;
  color: #ffffff;
}

.custom-input .ant-input::placeholder {
  color: #888a8d;
}

.custom-input .ant-input-password-icon svg {
  fill: #888a8d;
  /* Change icon color to white */
}

.custom-form-item label {
  color: #ffffff !important;
  /* Set label color to white */
}

.ant-checkbox-inner {
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: #ffffff40 !important;
}

.custom-form-item {
  min-height: 60px;
}

.custom-form-item .input:-webkit-autofill,
.custom-form-item .input:-webkit-autofill:hover,
.custom-form-item .input:-webkit-autofill:focus {
  /* border: 1px solid #4CAF50; */
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #0a1510 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.password input:-webkit-autofill,
.password input:-webkit-autofill:hover,
.password input:-webkit-autofill:focus {
  /* border: 1px solid #4CAF50; */
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #0a1510 inset;
  transition: background-color 5000s ease-in-out 0s;
}
