.tags-selection-dropdown ul{
    max-height: 300px;
    overflow-y: scroll;
}

.tags-selection-dropdown ul li{
    cursor: pointer;
}
.tags-selection-dropdown ul li:hover {
    background-color: wheat;
}