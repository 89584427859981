/* CustomStyles.css */
.custom-table .ant-checkbox {
  border: 1px solid #58ad89;
}

.custom-table .ant-checkbox-checked  {
  background-color: #58ad89;
}

.css-dev-only-do-not-override-1939xja .ant-checkbox-indeterminate .ant-checkbox-inner:after {
display: none !important;
}

.table-component .ant-table-content thead th{
  background:white;
  border-left: none;
}

.table-component .ant-table-content thead th span, .table-component .ant-table-content thead th{
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color:#888A8D
}

.table-component .ant-pagination-next, .table-component .ant-pagination-prev{
  border:1px solid #008001;
}

.table-component .ant-table-content tbody td,.tabletext,.table-component .ant-table-cell span{
  font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 18px;
text-align: left;
color:#070908
}