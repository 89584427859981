.user-profile {
  display: flex;
  align-items: center;
  gap: 16px;
  /* Adjust the spacing between elements as needed */
}

.user-name {
  font-size: 1.2rem;
  margin-left: 12px;
}

.icon-style {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  background-color: #888a8d;
  /* Gray background for unselected icon */
  color: white;
}

.menu-admin li:hover svg{
  color:white !important
}
.menu-admin li:hover span{
  color:white !important
}

.menu-admin .ant-menu-item-selected{
background-color: transparent !important;
}
