.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 10px 18px 10px;
  /* background: #fff; */
  /* Set the background color as needed */
  border-bottom: 1px solid #e8e8e8;
  /* Just an example border */
}

.info-display,
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.label {
  font-size: 14px;
  color: #888;
  /* Gray text for labels */
}

.value {
  font-size: 20px;
  font-weight: 600;
  color: #1e1d1d;
  /* Darker text for values */
}

.completion .label {
  align-self: flex-start;
}

.user-info {
  display: flex;
  align-items: center;
}

/* Additional styles for Avatar and Progress bar */

.user-profile {
  display: flex;
  align-items: center;
  gap: 16px;
  /* Adjust the spacing between elements as needed */
}

.user-name {
  font-size: 1.2rem;
  /* Adjust font size as needed */
  color: #000;
  /* Adjust font color as needed */
  margin-left: 12px;
  /* Spacing between the avatar and the name */
}

.icon-style {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  background-color: #888a8d;
  /* Gray background for unselected icon */
  color: white;
}
