@import "react-resizable/css/styles.css";

.icon-background {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin-right: 16px;
}

.icon-background-selected {
  background-color: #34c788;
  /* Green background for selected icon */
}

.icon-background-unselected {
  background-color: #ffffff33;
  /* Gray background for unselected icon */
}

/* Style for the dots in the submenu */
.submenu-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  /* Adjust the margin as needed */
  vertical-align: middle;
}

.submenu-dot-selected {
  background-color: #3ba55d;
  /* Green dot for selected submenu item */
}

.submenu-dot-unselected {
  background-color: #aaa;
  /* Gray dot for unselected submenu item */
}

/* Hover effect for all items */
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: white !important;
  /* Text color on hover */
  background-color: transparent !important;
  /* Ensure background doesn't change on hover */
}

/* Selected state for main and submenu items */

.ant-menu-dark .ant-menu-item-selected:hover,
.ant-menu-dark .ant-menu-submenu-item-selected:hover {
  color: white !important;
  background-color: transparent !important;
}

.sidebar-footer {
  /* padding: 16px; */
  background-color: #000;
  /* Or whatever color matches your design */
  color: #fff;
  /* For the text color */
}

.button-no-background {
  background: none;
  border: none;
  color: #fff;
  /* Adjust if you have a different preference for text color */
}

.button-no-background:hover {
  border: none;
}

.button-no-background:active {
  border: none;
}

.icon-wrapper {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin-right: 16px;
  background-color: #888a8d;
  /* Gray background for unselected icon */
  color: white;
}

.menu li {
  padding-left: 0 !important;
}

/* .submenu {
    max-height: 0;
    text-align: left;
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    overflow: hidden;
    transition: opacity 0.5s, transform 0.5s, max-height 0.5s, visibility 0s 0.5s;
}

.submenu-visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    max-height: 500px;
    transition: opacity 0.5s, transform 0.5s, max-height 0.5s, visibility 0s 0s;
} */

.submenu-item-content {
  transition: opacity 0.5s, transform 0.5s;
  opacity: 1; /* Default state */
}

.submenu-visible .submenu-item-content {
  opacity: 1;
  transform: translateY(0);
}

.submenu-hidden .submenu-item-content {
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden; /* Hide content, but may not be necessary if the parent already controls visibility */
  transition: opacity 0.5s, transform 0.5s;
}
