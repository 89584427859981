.input ::placeholder {
    /* Increase specificity by using a custom class */
    color: rgb(99, 98, 98);
    /* Your desired color */
    opacity: 1;
    /* Make sure the color isn't translucent */
}

.input .ant-select-selection-placeholder {
    /* Increase specificity by using a custom class */
    color: rgb(99, 98, 98);
    /* Your desired color */
    opacity: 1;
    /* Make sure the color isn't translucent */
}



.btn {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #888a8d;
  }
  
  .btnActive {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
  }
  
  .title {
    font-size: 56px;
    font-weight: 400;
    line-height: 72px;
    color: #ffffff;
  }
  
  .title span {
    color: #34c788;
  }
  
  .description {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #ffffff60;
  }
  

  .timer-text{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: center;
    color:white;
  }

  .challenge-title{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }

.challenge-stats{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color:#888A8D
  }

  .challenge-description{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;

  }

  .progress-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color:#070908
  }

  .progress-title{
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    color:#888A8D;
    text-transform: uppercase;
  }

  .module-completion {
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
color:#070908
  }

  .progress-title2{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
color:#888A8D    
  }

  .progress-text-percent{
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
color:#070908
  }

  .user-name{
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
color:#070908
  }

  .user-email{
    font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: center;
color:#888A8D
  }

  .card-title{
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
color:#070908
  }

  .progress-container-custom .ant-progress-inner{
    width:90px !important;
    height: 90px !important;
  }